import React from 'react';

const YouthEnterpriseProfile = () => {
    return (
        <div>
            <h1>Profile</h1>
            <p>Manage your profile information here.</p>
        </div>
    );
};

export default YouthEnterpriseProfile;
