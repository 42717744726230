import React from 'react';

const AllianceDashboard = () => {
    return (
        <div>
            <h1>Alliance Dashboard</h1>
            <p>Welcome to the Alliance Admin Dashboard.</p>
        </div>
    );
};

export default AllianceDashboard;
