import React from 'react';

const YouthEnterpriseDashboard = () => {
    return (
        <div>
            <h1>Youth Enterprise Dashboard</h1>
            <p>Welcome to the Youth Enterprise Dashboard.</p>
        </div>
    );
};

export default YouthEnterpriseDashboard;